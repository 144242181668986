export const state = () => ({
	expiredContent: [],
});

export const getters = {
	/*
	 * @returns {Boolean} - Returns true if site expiration feature flag is enabled
	 */
	canExpire: (state, getters, rootState) => {
		return rootState?.settings?.featureFlags?.SiteExpiration;
	},
	/*
	 * @param {Array} contentLibraryItems - Array of content library items
	 * @param {Function} itemCheckFunction - A function that checks other properties on the item
	 * @returns {Boolean} - Returns true if content is expired
	 */
	isExpired: (state, getters) => (contentLibraryItems, itemCheckFunction) => {
		if (!getters.canExpire) return false;
		return contentLibraryItems?.some(
			(item) =>
				state.expiredContent.includes(item.id) &&
				itemCheckFunction(item)
		);
	},
};

export const actions = {
	async fetchExpiredContent({ commit }) {
		const response = await this.$axios.$get(
			`/library/presets/content/expiration`
		);
		commit("setExpiredContent", response);
	},
};

export const mutations = {
	setExpiredContent(state, expiredContent) {
		state.expiredContent = expiredContent;
	},
};
